<template>
  <div>
    <div class="title">亚盘变化</div>
    <el-divider></el-divider>
    <div class="simple">
      买足彩[微博]总避免不了看亚盘数据分析。那什么是亚盘？亚盘的全称为让球式亚洲盘，它的竞猜对象主要为足球比赛。我们都知道，足球场上两支球队的实力很多时候是不一样的，那么，对其比赛结果进行博弈将很难做到公平竞猜，因为投注者大都会选择实力较强一方做为投注对象，赢利概率自然会很大，这样，这场博弈游戏就无法继续下去，由此，亚盘应运而生。亚盘起源于亚洲，但现在欧洲一些博彩公司也纷纷开出亚洲盘来占据市场。下面介绍的是盘口的基本要素：
    </div>
    <div class="body">
      <div class="panel">
        <div class="panel-title">
          1、什么是让球盘(独赢盘)
        </div>
        <div class="panel-content">
          让球盘口又叫“独赢盘”，即在指定的比赛中投注可能胜出的球队。例如，利物浦[微博]VS切尔西[微博]，如开盘为平手盘时投注者投注利物浦，若主队赢下至少1个球则视为利物浦独赢、投注者即获得利润。如切尔西获胜则投注利物浦失败，投注者要输掉全部本金。如果打成平局，则球队没有输赢、投注者没有盈亏，只退还本金部分。
        </div>
      </div>

      <div class="panel">
        <div class="panel-title">
          2、让球盘的三个组成部分
        </div>
        <div class="panel-content">
          让球盘由交战球队、让球数(即盘口)及贴水这三个部分组成。例如曼联[微博]0.86球半1.00富勒姆。其中曼联和富勒姆就是交战球队、球半即为让球盘数、0.86/1.00即为投注双方的获胜赔率又称“贴水”。
        </div>
      </div>

      <div class="panel">
        <div class="panel-title">
          3、什么是贴水？
        </div>
        <div class="panel-content">
          贴水也可称为水位，即投注比赛双方的获胜赔率。在澳门盘中，英超、意甲等联赛中两边球队的获胜“贴水”总和为1.86，即某一方是1.00水位时另一方则是0.86水位。如上例，假设投注者投注1,000元买曼联独赢，如曼联取胜投注者得到的回报则是1,000×0.86=860元，如投注富勒姆后富勒姆不败，投注者则得到1,000×1.00=1,000元。
        </div>
      </div>

      <div class="panel">
        <div class="panel-title">
          4、 什么是上、下盘口
        </div>
        <div class="panel-content">
          让球一方称之为上盘，而被让球一方则称之为下盘。例如曼联VS富勒姆，曼联让球半给富勒姆，此时曼联就是让球盘口中的上盘球队，富勒姆则为下盘球队。
        </div>
      </div>

      <div class="panel">
        <div class="panel-title">
          5、如何计算盘口输赢
        </div>
        <div class="panel-content">
          所谓盘口输赢计算，就是让球规则。较常见的让球盘口规则具体如下：
          <br />平手盘 双方平开，互不让球 <br />平手/半球
          打平输一半，净胜一球全赢 <br />半球 打平全输，净胜一球全赢
          <br />半球/一球 打平全输，净胜一球赢一半，净胜两球全赢 <br />一球
          净胜一球算平，净胜两球全赢 <br />一球/球半
          净胜一球输一半，净胜两球全赢 <br />球半 净胜一球全输，净胜两球全赢
          <br />球半/两球 净胜一球全输，净胜两球赢一半，净胜三球全赢 <br />两球
          净胜两球算平，净胜三球全赢 <br />两球/两球半
          净胜两球输一半，净胜三球全赢
        </div>
      </div>

      <div class="panel">
        <div class="panel-title">
          6、什么是走盘
        </div>
        <div class="panel-content">
          如让球规则中的平手盘、一球盘、两球盘这三类盘口，都是在打平、净胜一球和净胜两球时被视作平局。当出现这类情况时，投注上下盘均视作无效，将退还本金，即为“走
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.title {
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #16a085;
  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 15px;
    background: #16a085;
    margin-right: 5px;
  }
}
.el-divider {
  margin-top: 5px;
  margin-bottom: 15px;
}
.simple {
  background: #f6f7f8;
  border-radius: 6px;
  padding: 15px;

  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
  color: #333333;
  margin-bottom: 20px;
}
.body {
  margin-bottom: 15px;
}
.panel {
  margin-bottom: 20px;
  font-size: #333;
  line-height: 26px;
  .panel-title {
    font-weight: bold;
  }
}
.row {
  margin-top: 20px;
  display: flex;
  img {
    margin-right: 35px;
  }
}
</style>
